export const medias = [
  {
    value: 'fbmessenger',
    label: 'Facebook Messenger',
  },
  {
    value: 'fbcomments',
    label: 'Facebook Comments',
  },
  {
    value: 'igdirect',
    label: 'Instagram Direct',
  },
  {
    value: 'igcomments',
    label: 'Instagram Comments',
  },
  {
    value: 'sms',
    label: 'SMS',
  },
  {
    value: 'whatsapp',
    label: 'WhatsApp',
  },
  {
    value: 'linkedincomments',
    label: 'LinkedIn Comments',
  },
  {
    value: 'linkedindirect',
    label: 'LinkedIn Direct',
  },
  {
    value: 'googleplaycomments',
    label: 'Google Play Comments',
  },
  {
    value: 'xcomments',
    label: 'X Comments',
  },
  {
    value: 'xdirect',
    label: 'X Direct',
  },
  {
    value: 'googlemybusiness',
    label: 'Google My Business',
  },
  {
    value: 'applestorecomments',
    label: 'Apple Store Comments',
  },
];
