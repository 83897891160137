/* eslint-disable react/no-danger */
/* eslint-disable prettier/prettier */
import { AlignLeftOutlined } from '@ant-design/icons';
import { Card, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FaFilter, FaSyncAlt } from 'react-icons/fa';
import { v4 } from 'uuid';
import Table from '../../../components/Table';
import { useApi } from '../../../hooks/api';
import { ILogInteractionRequest } from '../../../interfaces/logInteractionRequest';
import { IPageable, DEFAULT_PAGEABLE } from '../../../interfaces/pageable';
import { IActionHeaderConfig, ITableColumn } from '../../../interfaces/table';
import LogIntegrationRequestFilters from '../../../components/LogIntegrationRequestFilters';
import { medias } from '../../../constants/medias';

interface IMedia {
  value: string;
  label: string;
}

const LogInteractionRequest: React.FC = () => {
  const { api } = useApi();
  const [loading, setLoading] = useState(false);
  const [interactionsRequests, setInteractionsRequests] = useState<
    ILogInteractionRequest[]
  >([]);
  const [pageable, setPageable] = useState<IPageable>(DEFAULT_PAGEABLE);
  const [content, setContent] = useState<string>();
  const [showContent, setShowContent] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    media_name: '',
    broker: '',
    client: '',
    integration: '',
    request_sent_at: '',
    origin: '',
  });

  const loadLogs = useCallback(
    async (
      pageNumber = 1,
      media_name = filters.media_name,
      broker = filters.broker,
      client = filters.client,
      integration = filters.integration,
      request_sent_at = filters.request_sent_at,
      origin = filters.origin,
    ) => {
      setLoading(true);
      try {
        const response = await api.get('/logs/interaction-request/paginated', {
          params: {
            page: pageNumber - 1,
            media_name,
            broker,
            client,
            integration,
            request_sent_at,
            origin,
          },
        });
        setInteractionsRequests(response.data.data);
        setPageable(response.data);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    },
    [
      api,
      filters.media_name,
      filters.broker,
      filters.client,
      filters.integration,
      filters.request_sent_at,
      filters.origin,
    ],
  );

  const showContentModal = useCallback((data: string) => {
    setContent(data);
    setShowContent(true);
  }, []);

  const mediaMapping: { [key: string]: string } = medias.reduce(
    (acc: { [key: string]: string }, { value, label }: IMedia) => {
      acc[value] = label;
      return acc;
    },
    {} as { [key: string]: string },
  );

  const tableColumns: ITableColumn[] = [
    {
      id: v4(),
      name: 'Canal',
      dataIndex: 'media_name',
      responsive: true,
      renderColumn: ({ media_name }: ILogInteractionRequest) => {
        return <p>{mediaMapping[media_name] || media_name}</p>;
      },
    },
    {
      id: v4(),
      name: 'Broker',
      dataIndex: 'broker',
      responsive: true,
    },
    {
      id: v4(),
      name: 'Cliente',
      dataIndex: 'client',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Integração',
      dataIndex: 'integration',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Data',
      dataIndex: 'request_sent_at',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Origem',
      dataIndex: 'origin',
      responsive: false,
    },
  ];

  const headerActions: IActionHeaderConfig[] = [
    {
      titleAction: 'Filtros',
      icon: <FaFilter />,
      action: () => {
        setShowFilters(true);
      },
    },
    {
      titleAction: 'Atualizar',
      icon: <FaSyncAlt />,
      action: () => {
        // loadLogInteractionsStatus();
      },
    },
  ];

  const onSearch = useCallback(
    (
      media_name: string,
      broker: string,
      client: string,
      integration: string,
      request_sent_at: string,
      origin: string,
    ) => {
      setFilters({
        media_name,
        broker,
        client,
        integration,
        request_sent_at,
        origin,
      });

      loadLogs(
        1,
        media_name,
        broker,
        client,
        integration,
        request_sent_at,
        origin,
      );
      setShowFilters(false);
    },
    [loadLogs],
  );

  useEffect(() => {
    loadLogs();
  }, [loadLogs]);

  return (
    <Card>
      <Table
        title="Log de Interações de Requisições"
        actionsConfig={headerActions}
        data={interactionsRequests}
        keyIndex="id"
        tableColumns={tableColumns}
        tableActions={[
          {
            name: 'Conteudo',
            icon: <AlignLeftOutlined />,
            action: (data: ILogInteractionRequest) => {
              showContentModal(data.content);
            },
            disabled: () => {
              return false;
            },
          },
        ]}
        tablePagination={{
          current: pageable.page + 1,
          defaultPageSize: pageable.elementsPerPage,
          totalItems: pageable.totalElements,
        }}
        onChangePage={loadLogs}
        loading={loading}
      />

      <LogIntegrationRequestFilters
        closeFilters={() => setShowFilters(false)}
        showFilters={showFilters}
        search={onSearch}
      />

      <Modal
        title="Dados enviados na Requisição"
        visible={showContent}
        onOk={() => setShowContent(false)}
        onCancel={() => setShowContent(false)}
        width={800}
      >
        <div>
          <pre
            className="json-content"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(content, null, 2),
            }}
          />
        </div>
      </Modal>
    </Card>
  );
};

export default LogInteractionRequest;
