/* eslint-disable react/jsx-indent */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useState } from 'react';

import { Card, Flex, Input, Select, Typography } from 'antd';

import { useApi } from '../../hooks/api';

import './styles.scss';

const ClientsConsumptionReport: React.FC = () => {
  const currentDate = new Date();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adiciona 1 porque Janeiro é 0!
  const currentYear = currentDate.getFullYear().toString();

  const { api } = useApi();

  const [dashboardUrl, setDashboardUrl] = useState<string>('');

  const [filtersData, setFiltersData] = useState({
    mes: currentMonth,
    ano: currentYear,
  });

  const loadDashboardConsumption = useCallback(async () => {
    try {
      const response = await api.get('/metabase/dashboard/consumer', {
        params: {
          competencia: `${filtersData.ano}${filtersData.mes}`,
        },
      });

      const { data } = response;
      setDashboardUrl(data.iframeUrl);
    } catch (error) {
      console.error('error', error);
    }
  }, [api, filtersData.ano, filtersData.mes, filtersData]);

  useEffect(() => {
    loadDashboardConsumption();
  }, [filtersData, filtersData.ano, filtersData.mes, loadDashboardConsumption]);

  return (
    <Card className="card-reports">
      <Typography.Title level={3}>
        Quantidade de mensagens por canal
      </Typography.Title>

      <Flex gap={5} style={{ marginBottom: '20px' }}>
        <div>
          <Typography.Title level={5}>Mês</Typography.Title>
          <Select
            allowClear
            placeholder="Selecione um mês"
            className="dcs-select"
            showSearch
            filterOption
            optionFilterProp="children"
            defaultValue={filtersData.mes}
            onChange={value => {
              setFiltersData({ ...filtersData, mes: value });
            }}
          >
            <Select.Option value="01">Janeiro</Select.Option>
            <Select.Option value="02">Fevereiro</Select.Option>
            <Select.Option value="03">Março</Select.Option>
            <Select.Option value="04">Abril</Select.Option>
            <Select.Option value="05">Maio</Select.Option>
            <Select.Option value="06">Junho</Select.Option>
            <Select.Option value="07">Julho</Select.Option>
            <Select.Option value="08">Agosto</Select.Option>
            <Select.Option value="09">Setembro</Select.Option>
            <Select.Option value="10">Outubro</Select.Option>
            <Select.Option value="11">Novembro</Select.Option>
            <Select.Option value="12">Dezembro</Select.Option>
          </Select>
        </div>
        <div>
          <Typography.Title level={5}>Ano</Typography.Title>
          <Input
            className="dcs-input"
            placeholder="Ano"
            defaultValue={filtersData.ano}
            onChange={e => {
              setFiltersData({ ...filtersData, ano: e.target.value });
            }}
          />
        </div>
      </Flex>
      {/* </Form.Item> */}
      <iframe
        title="Dash"
        src={dashboardUrl}
        width="100%"
        height="700px"
        style={{ border: 0 }}
      />
    </Card>
  );
};

export default ClientsConsumptionReport;
